import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'

import * as api from './api/index'

import 'normalize.css/normalize.css'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'
import fullScreenContainer from "./components/dv-full-screen/dv-full-screen"
import countTo from 'vue-count-to'
import scroll from "@/directives/scroll";

import '@/icons' // icon
import '@/permission' // permission control

Vue.component('dvFullScreenContainer', fullScreenContainer)
Vue.component('countTo', countTo)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.directive('scroll', scroll)

Vue.prototype.$api = api

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
