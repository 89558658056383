import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login/login'),
        hidden: true
    },
    {
        path: '/',
        component: () => import('@/views/home/home'),
    },

    {
        path: '/404',
        component: () => import('@/views/unknown/404'),
        hidden: true
    },

    {
        path: '/403',
        hidden: true,
        component: () => import('@/views/unknown/403'),
    },

    {
        path: '*',
        redirect: '/404',
        hidden: true
    },
]

const createRouter = () => new VueRouter({
    // mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

export const resetRouter = function () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
