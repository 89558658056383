import axios from 'axios'
import router from '../router'
import Vue from 'vue'
import {API_BASE_URL} from "@/config/config";
import {getToken, removeToken} from '@/utils/auth'

export const source = axios.CancelToken.source()

const config = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        // 'Merchant-token' : getToken()  // TODO  加了这个之后，登录的时候就登录不进来了
    },
    baseURL: API_BASE_URL,
    timeout: 0,
    responseType: 'json',
    maxContentLength: -1,
    // cancelToken: source.token,
    withCredentials: true,
    crossDomain: true,
    validateStatus(status) {
        return status >= 200 && status < 510
    },
}

const service = axios.create(config)


service.interceptors.request.use(function (res) {
    res.headers['Bi-token'] = getToken()
    return res
})

service.interceptors.response.use(response => {

    if (response.status == 500) {
        Vue.prototype.$notify({
            title: '系统异常，请稍后重试',
            dangerouslyUseHTMLString: true,
            message: '<strong class="my-notify">请检查API是否异常</strong>', //请检查API是否异常
        })
        // throw new Error('服务器500异常')
    } else if (response.status == 429) {
        Vue.prototype.$notify({
            title: '429',
            dangerouslyUseHTMLString: true,
            message: '<strong class="my-notify">Too Many Request</strong>', //请检查API是否异常
        })
        throw new Error('429')
    }

    // 重定向到登录页面
    if (response && response.data && response.data.hasOwnProperty("data") && response.data.data == 'no_login') {
        removeToken()
        router.replace({path: '/login'});
        return response;
    } else {
        return response;
    }
    return response
}, error => {
    if (!error.response) {
        Vue.prototype.$notify({
            title: 'Network Error',
            dangerouslyUseHTMLString: true,
            message: '<strong class="my-notify">请检查 API 是否异常</strong>',
        })
    }

    // 判断请求超时
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
        Vue.prototype.$message({
            type: 'warning',
            message: '请求超时',
        })
    }
    return Promise.reject(error)
},)


export default service
