const HTTP_URL = 'https://apartment-sys.bittyun.com'  //服务器地址-正式服

// const HTTP_URL = 'https://apartment-test-sys.bittyun.com'  //服务器地址-测试服

//接口请求地址
const API_BASE_URL = HTTP_URL + '/api/'

//大屏服务器地址
const BI_HTTP_URL = HTTP_URL.includes('hp-test-sys') ? 'https://hp-test-bi.zoomyoung.cn' : 'https://huangpu-bi.zoomyoung.cn'

const UEDITOR_CONFIG = {
    // 编辑器自动被内容撑高
    autoHeightEnabled: true,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: '100%',
    serverUrl: HTTP_URL + '/ueditor-php/controller.php',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/UEditor/',
    toolbars: [
        [
            'source', '|', 'undo', 'redo', '|',
            'bold', 'italic', 'underline', 'strikethrough',
            'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor',
            'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
            'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
            'simpleupload', 'insertimage', 'insertvideo', 'music',
            'horizontal', 'date', 'time',
            'inserttable', 'link', 'source'
        ]
    ],
    autoFloatEnabled: false,
    catchRemoteImageEnable: false,//设置是否抓取远程图片。不关闭这个会导致多次粘贴的时候，图片链接会被修改，导致图片丢失
}

export {
    HTTP_URL,
    API_BASE_URL,
    BI_HTTP_URL,
    UEDITOR_CONFIG
}
